<template>
  <div>
      <transition name="van-fade">
        <div class="login-modal" v-show="isShowModal">
            <div class="close-modal"  @click="isShowModal=!isShowModal"></div>
            <van-form @submit="onSubmit" class="login-form">
                <van-field
                  v-model="username"
                  name="用户名"
                  label="用户名"
                  placeholder="用户名"
                  :rules="[{ required: true, message: '请填写用户名' }]"
                />
                <van-field
                  v-model="password"
                  type="password"
                  name="密码"
                  label="密码"
                  placeholder="密码"
                  :rules="[{ required: true, message: '请填写密码' }]"
                />
                <div style="margin: 16px;">
                  <van-button round block type="info" native-type="submit">提交</van-button>
                </div>
            </van-form>
        </div>
      </transition>  
  </div>
</template>

<script>
let redirectUrl;
import {GoLogin} from '@/request/api'
import Qs from "qs";
export default {
    beforeRouteEnter(to, from, next) {
        if (to.query && to.query.redirect) {
            redirectUrl = to.query.redirect
        } else {
            redirectUrl = from.fullPath
        }
        next();
    },
  data () {
    return {
      avatarSrc: "https://ksytimg.oss-cn-beijing.aliyuncs.com/wxImages/assets/images/avatar.png",
      username: '',
      password: '',
      isShowModal: true,
      nickname: "用户登录"
 
    }
  },
  created() {
    // 组件刚创建的时候需要判断用户是否登录,判断localStorage有没有token值
    // 如果有就应该天上用户信息
    let token =  localStorage.getItem("token")
    if (token) {
      // 表示已经有登录状态,就要渲染用户信息
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.nickname = userInfo.nickname;
      this.avatarSrc = userInfo.avatar;
    }
  },
  methods: {
     onSubmit(values) {
         let that = this;
      // console.log('submit', values);

      let username = values["用户名"];
      let pwd = values["密码"];
      GoLogin({
        username,
        pwd
      }).then(res=> {
        // console.log(res.data);
          console.log(redirectUrl);
          console.log(res);
          if(res.data.status == 1){
          // 登录成功了
          // 1.提示框提示登录成功
          this.$toast.success({msg:'登录成功'});
          // 2.把token保存到本地存储
          localStorage.setItem("token", res.data.data.jwt);
          localStorage.setItem("userInfo", JSON.stringify(res.data.data.userInfo));

          // 3.1s后关闭模态框
          setTimeout(() => {
            that.isShowModal = !this.isShowModal;
            // 4.把拿到的userInfo填写到页面上
            that.nickname = res.data.data.userInfo.nickname;
            that.avatarSrc = res.data.data.userInfo.avatar;
          }, 1000);
              this.$router.push({
                  name:'Index',
                  params:{

                  }
              })
          
        }
      })
    },
    openModal() {
      // 判断用户是否登录,如果登录就直接return
      // 没有登录就取反
      let token = localStorage.getItem("token")
      if(token) {
        return
      }
      this.isShowModal = !this.isShowModal
    }
  },
}
</script>
 
<style lang = "less" scoped>
.login-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  .login-form {
    width: 90%;
    background-color: #fff;
    padding-bottom: .2rem;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -45%;
    box-sizing: border-box;
  }
  .close-modal{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}
  .van-row {
  padding: 0.2rem 4%;
  background: #333;
  color: #fff;
  .van-col {
    line-height: 0.7rem;
    font-size: 0.18rem;
    img {
      width: 0.7rem;
      border-radius: 50%;
      display: block;
    }
    &:last-child {
      text-align: right;
    }
  }
}


</style>